import { graphql } from 'gatsby'
import get from 'lodash/get'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'
import Hero from '../components/molecules/hero'
import OldCtaBlock from '../components/organisms/old-cta-block'
import PeopleSection from '../components/organisms/people-section'

export const largeSquiggle =
  require('../assets/large-squiggle-gradient.svg') as string
const headerStripImage = require('../assets/header-strip.svg') as string
const headerStripImageMobile =
  require('../assets/header-strip-mobile.png') as string

const Title = styled.h2`
  margin-top: 6.6rem;
  margin-bottom: 1.3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2rem;
  }
`

const HeaderStrip = styled.div`
  background-image: url(${headerStripImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 6rem;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    background-image: url(${headerStripImageMobile});
    background-position: top center;
    height: 4rem;
  }
`

export const Squiggle = styled.img`
  width: 13.8rem;
`

export const Story = styled.div`
  display: flex;
  width: 100%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-top: 3.2rem;
  }
`

const StyledText = styled.div`
  margin-right: 13.5rem;
  margin-top: 6rem;
  white-space: pre-line;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-right: 0;
    margin-bottom: 7.5rem;
    margin-top: 0;
  }
  p {
    margin-top: 0;
  }
`

export const DesktopView = styled.div`
  display: block;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const MobileView = styled.div`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: block;
  }
`

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    allContentfulPage: {
      edges: {
        node: {
          title: string
          slug: string
          heroImage: object
        }
      }
    }
  }
}

const About = (props: Props): JSX.Element => {
  const allPages = get(props, 'data.allContentfulPage.edges')
  const ourMinister = get(props, 'data.ourMinister.nodes')
  const teamMembers = get(props, 'data.teamMembers.nodes')
  const boardMembers = get(props, 'data.boardMembers.nodes')
  const currentPath = get(props, 'location.pathname')

  const currentPage = allPages.find(
    (page) => get(page, 'node.slug') === currentPath,
  )

  return (
    <Layout currentPath={currentPath}>
      <Helmet title='Pacific business support | Education & financial support | About Pacific Business Trust' />
      <Hero heroImage={currentPage.node.heroImage} fullWidth />
      <HeaderStrip />
      <Title>Building strong futures, together.</Title>
      <Squiggle src={largeSquiggle} alt='Squiggle' />
      <Story>
        <StyledText>{currentPage?.node?.description?.description}</StyledText>
        <DesktopView>
          <OldCtaBlock small />
        </DesktopView>
      </Story>
      <PeopleSection title='Our Leaders' people={ourMinister} />
      <PeopleSection title='Meet the Team' people={teamMembers} />
      <PeopleSection title='Meet the Board' people={boardMembers} />
      <MobileView>
        <OldCtaBlock small />
      </MobileView>
    </Layout>
  )
}

export const PersonFragment = graphql`
  fragment Person on ContentfulPersonConnection {
    nodes {
      createdAt
      bio {
        bio
      }
      facebook
      id
      linkedIn
      name
      orderNumber
      role
      image {
        fixed(width: 600, height: 600, quality: 80) {
          src
        }
      }
      type
    }
  }
`

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      edges {
        node {
          title
          slug
          description {
            description
          }
          heroImage {
            ...GatsbyFluidImageFields
          }
        }
      }
    }
    ourMinister: allContentfulPerson(
      sort: { fields: [orderNumber], order: ASC }
      filter: { type: { eq: "Our Minister" } }
    ) {
      ...Person
    }
    teamMembers: allContentfulPerson(
      sort: { fields: [orderNumber], order: ASC }
      filter: { type: { eq: "Team Member" } }
    ) {
      ...Person
    }
    boardMembers: allContentfulPerson(
      sort: { fields: [orderNumber], order: ASC }
      filter: { type: { eq: "Board Member" } }
    ) {
      ...Person
    }
  }
`

export default About
