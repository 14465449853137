import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import AltStyledButton from '../molecules/button'

const BlueBlockSrc = require('../../assets/blue-block.svg') as string
const SunsetSrc = require('../../assets/sunset.svg') as string

const CTABlockContainer = styled.div`
  background-image: url(${BlueBlockSrc});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    left: 0;
    right: 0;
    margin: 0;
  }
`

const Inner = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  height: 56.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
  }
`

const Sunset = styled.img`
  height: ${(props: Props): string => (props.small ? '16.6rem' : '26rem')};
  width: auto;
  margin-right: ${(props: Props): string => (props.small ? '0' : '12.6rem')};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: 16.6rem;
    width: auto;
    margin: 4rem;
  }
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.h2`
  color: ${theme.colours.white};
  text-align: center;
  font-size: ${(props: Props): string => (props.small ? '1.8rem' : '')};
  margin-top: ${(props: Props): string => (props.small ? '3.2rem' : '')};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.8rem;
    margin-bottom: 5rem;
  }
`

const CTA = styled(AltStyledButton)`
  width: ${(props: Props): string => (props.small ? '20.6rem' : '30rem')};
  margin-top: ${(props: Props): string => (props.small ? '4.8rem' : '')};
`

const SmallContainer = styled.div`
  width: 28.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colours.primary};
  padding: 4rem;
  margin: 0 auto;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
`

interface Props {
  small?: boolean
}

const OldCtaBlock = (props: Props): JSX.Element => {
  const image = <Sunset {...props} src={SunsetSrc} alt='Sunset' />
  const text = (
    <Text {...props}>
      Fast-track your business growth and success with the right support behind
      you.
    </Text>
  )
  const cta = (
    <Link to='/join-community/'>
      <CTA {...props} alternative>
        Join Community
      </CTA>
    </Link>
  )

  return props.small ? (
    <SmallContainer data-test-id='cta-block'>
      {image}
      {text}
      {cta}
    </SmallContainer>
  ) : (
    <CTABlockContainer>
      <Inner>
        {image}
        <RightSide>
          {text}
          {cta}
        </RightSide>
      </Inner>
    </CTABlockContainer>
  )
}

export default OldCtaBlock
